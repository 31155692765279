import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

/**
 * Directiva que renderiza un icono.
 */
@Directive({
  selector: '[appIcon]',
  standalone: true
})
export class IconDirective implements OnChanges {
  private baseClass = 'pi';

  /** icono */
  @Input() icon!: string;
  /** indica si el icono es clickeable */
  @Input() clickable = false;

  @HostBinding('class') elementClass = this.baseClass;

  /**
   * Detecta cambios en las propiedades de la directiva.
   * @param changes cambios detectados.
   */
  ngOnChanges(changes: SimpleChanges) {
    const icon = changes['icon']
      ? (changes['icon'].currentValue as string)
      : this.icon;
    const clickable = changes['clickable']
      ? (changes['clickable'].currentValue as boolean)
      : this.clickable;

    this.elementClass = `${this.baseClass} pi-${icon}`;
    this.elementClass += clickable ? ' app-icon-clickable' : '';
  }
}
